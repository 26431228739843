// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-templates-categories-jsx": () => import("./../../../src/templates/Categories.jsx" /* webpackChunkName: "component---src-templates-categories-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/Category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-cities-jsx": () => import("./../../../src/templates/Cities.jsx" /* webpackChunkName: "component---src-templates-cities-jsx" */),
  "component---src-templates-city-jsx": () => import("./../../../src/templates/City.jsx" /* webpackChunkName: "component---src-templates-city-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-overview-jsx": () => import("./../../../src/templates/Overview.jsx" /* webpackChunkName: "component---src-templates-overview-jsx" */),
  "component---src-templates-queries-jsx": () => import("./../../../src/templates/Queries.jsx" /* webpackChunkName: "component---src-templates-queries-jsx" */),
  "component---src-templates-state-jsx": () => import("./../../../src/templates/State.jsx" /* webpackChunkName: "component---src-templates-state-jsx" */),
  "component---src-templates-states-jsx": () => import("./../../../src/templates/States.jsx" /* webpackChunkName: "component---src-templates-states-jsx" */),
  "component---src-templates-technology-jsx": () => import("./../../../src/templates/Technology.jsx" /* webpackChunkName: "component---src-templates-technology-jsx" */)
}

